import type { createHTTPClient } from '@elohello/http-client';
import { createServiceHTTPClientProvider } from '@elohello/service-utils';
import {
  createBoundServiceMutationHookCreator,
  createBoundServiceQueryHooksCreator,
} from '@elohello/service-utils/react-query';
import { z } from 'zod';

export const [HTTPClientProvider, useHTTPClient] =
  createServiceHTTPClientProvider<ReturnType<typeof createHTTPClient>>({
    name: 'API',
  });

export const createAPIQueryHooks = createBoundServiceQueryHooksCreator({
  queryKeyPrefix: 'medizzy-api',
  useClient: useHTTPClient,
});

export const createAPIMutationHook = createBoundServiceMutationHookCreator({
  useClient: useHTTPClient,
});

export const paginationRequestSchema = z.object({
  page: z.number().nonnegative().optional(),
  pageSize: z.number().positive().optional(),
});

export const paginationResponseSchema = z.object({
  pagination: z.object({
    page: z.number(),
    size: z.number(),
    total: z.number(),
  }),
});
